<template>
  <div v-if="show" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <h2>Φόρμα Παραπόνων</h2>
      <form @submit.prevent="submit">
        <label for="name">Όνομα / Επώνυμο:</label>
        <input v-model="form.name" type="text" id="name" />
        <p v-if="errors.name" class="error">{{ errors.name }}</p>

        <label for="voucher">Voucher:</label>
        <input v-model="form.voucher" type="text" id="voucher" />
        <p v-if="errors.voucher" class="error">{{ errors.voucher }}</p>

        <label for="phone">Τηλέφωνο:</label>
        <input v-model="form.phone" type="tel" id="phone" />
        <p v-if="errors.phone" class="error">{{ errors.phone }}</p>

        <label for="email">Email:</label>
        <input v-model="form.email" type="email" id="email" />
        <p v-if="errors.email" class="error">{{ errors.email }}</p>

        <label for="complaint">Παράπονο:</label>
        <textarea v-model="form.complaint" id="complaint" rows="5"></textarea>
        <p v-if="errors.complaint" class="error">{{ errors.complaint }}</p>

        <button type="submit">Υποβολή</button>
        <button type="button" @click="closeModal" class="close-btn">
          Κλείσιμο
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import parsePhoneNumberFromString from "libphonenumber-js";
import validator from "validator";

export default {
  props: ["show", "submitForm"],
  data() {
    return {
      form: {
        name: "",
        voucher: "",
        phone: "",
        email: "",
        complaint: "",
      },
      errors: {},
    };
  },
  methods: {
    validateForm() {
      this.errors = {};

      if (
        validator.isEmpty(this.form.complaint.trim()) ||
        !/[\w\u0370-\u03FF]+/.test(this.form.name)
      ) {
        this.errors.complaint = "Το όνομα είναι υποχρεωτικό.";
      }

      // Validate voucher field against multiple regex patterns
      const slmTrackingRegex = /^[A-Za-z0-9]{13}$/; // 13 characters
      const cargusTrackingNumberRegex = /^\d{9}[-]\d{1,2}$/; // 9 digits - 1
      const cargusTrackingRegex = /^\d{9}$/; // 9 digits
      const cargusReturnTrackingRegex = /^(SR)\d{9,}([-]\d{1,2})?$/; // SR9+ digits
      const zaraTrackingRegex = /^\d{20}$/; // 20 digits
      const diakinisisTrackingRegex = /^[W]\d{7,9}[-]\d{1,2}$/; // W{soid}-1

      const isValidVoucher =
        slmTrackingRegex.test(this.form.voucher) ||
        cargusTrackingNumberRegex.test(this.form.voucher) ||
        cargusTrackingRegex.test(this.form.voucher) ||
        cargusReturnTrackingRegex.test(this.form.voucher) ||
        zaraTrackingRegex.test(this.form.voucher) ||
        diakinisisTrackingRegex.test(this.form.voucher);

      if (!isValidVoucher) {
        this.errors.voucher = "Ο κωδικός voucher δεν είναι έγκυρος.";
      }

      const allowedCountries = [
        "GR",
        "CY",
        "AL",
        "BG",
        "GE",
        "IT",
        "RU",
        "TR",
        "GB",
        "DE",
        "FR",
        "ES",
        "US",
        "CA",
        "PT",
        "NL",
        "BE",
        "GI",
        "LU",
        "IE",
        "IS",
        "MT",
        "FI",
        "HU",
        "LT",
        "LV",
        "EE",
        "MD",
        "AM",
        "BY",
        "AD",
        "MC",
        "SM",
        "VA",
        "UA",
        "RS",
        "ME",
        "XK",
        "HR",
        "SI",
        "BA",
        "MK",
        "RO",
        "CH",
        "CZ",
        "SK",
        "LI",
        "AT",
        "DK",
        "SE",
        "NO",
        "PL",
        "AZ",
      ];
      const phoneNumber = parsePhoneNumberFromString(this.form.phone, {
        defaultCountry: "GR",
      });
      console.log(phoneNumber);
      if (!phoneNumber || !allowedCountries.includes(phoneNumber.country)) {
        this.errors.phone = "Το τηλέφωνο πρέπει να είναι έγκυρο.";
      }

      if (!validator.isEmail(this.form.email)) {
        this.errors.email = "Μη έγκυρο email.";
      }

      if (
        validator.isEmpty(this.form.complaint.trim()) ||
        !/[\w\u0370-\u03FF]+/.test(this.form.complaint)
      ) {
        this.errors.complaint =
          "Το παράπονο πρέπει να περιέχει τουλάχιστον μία λέξη.";
      }

      return Object.keys(this.errors).length === 0;
    },
    async submit() {
      if (!this.validateForm()) return;
      let r = await this.submitForm(
        this.form.name,
        this.form.email,
        this.form.phone,
        this.form.voucher,
        this.form.complaint
      );
      console.log(r);
      this.closeModal();
    },
    clearForm() {
      this.form.name = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.voucher = "";
      this.form.complaint = "";
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

label {
  display: block;
  margin-top: 10px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  margin-top: 15px;
  width: 100%;
  padding: 10px;
  background-color: #f58220;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.close-btn {
  background: gray;
  margin-top: 10px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 2px;
}
</style>
