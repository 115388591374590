<template>
  <div :class="'flex-wrapper-app'">
    <Navbar />
    <main :class="'search-main'">
      <div class="container">
        <Loader v-if="isLoading" />
        <div class="content-wrapper">
          <h1 class="search-title">Εξυπηρέτηση σχετικά με την αποστολή</h1>

          <div class="contact-info">
            <h4>
              <span>Τηλέφωνο: </span> &nbsp;
              <a href="tel:2111982107">211 198 2107</a>
            </h4>
            <h4>
              <span>Email: </span> &nbsp;
              <a href="mailto:help@skroutzdelivery.gr"
                >help@skroutzdelivery.gr</a
              >
            </h4>
            <h4 class="operating-hours">
              <span>Ώρες Λειτουργίας:</span>&nbsp;
              <div class="hours-content">
                Δευτέρα - Παρασκευή: 09:00 - 20:00<br />
                Σάββατο: 09:00 - 18:00<br />
                Κυριακή: Κλειστά<br />
                <span class="disclaimer">*Εκτός επίσημων αργιών</span>
              </div>
            </h4>
          </div>
        </div>
        <div>
          <button @click="openModal">
            Φόρμα Παραπόνων
          </button>
        </div>
        <!-- <Alert /> -->
        <div v-if="errorMessage !== null">
          <h1 class="review-title">
            {{ errorMessage }}
          </h1>
        </div>
        <ComplaintModal
          :show="isModalOpen"
          @close="isModalOpen = false"
          :submitForm="submitForm"
        />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../pages-sections/Navbar.vue";
import Footer from "../pages-sections/Footer.vue";
import Loader from "../loader/Loader.vue";
import ComplaintModal from "../pages-sections/ComplaintModal.vue";
import axios from "axios";

export default {
  name: "Home",
  components: {
    Navbar,
    Footer,
    Loader,
    ComplaintModal,
  },

  watch: {
    $route: "checkUrlHash",
  },
  mounted() {
    this.checkUrlHash();
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      errorMessage: null,
    };
  },
  methods: {
    checkUrlHash() {
      if (window.location.hash.length > 1) {
        const trackId = window.location.hash.replace("#", "");
        this.requestTracking(trackId);
      } else {
        this.isTrackIdValid = false;
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    async submitForm(name, email, phone, voucher, complaint) {
      this.isLoading = true;
      await axios
        .post(`${process.env.VUE_APP_COURIER_API}/user/complaintForm`, {
          name: name,
          email: email,
          phone: phone,
          complaint: complaint,
          voucher: voucher,
        })
        .then((r) => {
          if (r.status === 200) {
            this.errorMessage = "Η φόρμα υποβλήθηκε επιτυχώς!";
          }
          this.isLoading = false;
          return true;
        })
        .catch((err) => {
          if (err.response?.data?.message) {
            this.errorMessage = err.response.data.message;
          } else {
            this.errorMessage =
              "Η φόρμα δεν υποβλήθηκε, προσπάθησε ξανά ή επικοινώνησε μαζί μας.";
          }
          this.isLoading = false;
          return false;
        });
    },
  },
};
</script>

<style lang="scss">
.review-title {
  height: 50px;
  line-height: 34px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 26px;
  width: 490px;
  letter-spacing: 0px;
}
.flex-wrapper-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.search-main {
  flex-grow: 1;
  display: flex;
  background: url("../../assets/images/graphic-bg-search-page.svg") no-repeat;
  padding: 145px 0 156px;
}

.container {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 20px;
}

.content-wrapper {
  max-width: 490px;
}

.search-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 16px;
}

.hours-content {
  margin-top: 8px;
}

.disclaimer {
  font-weight: bold;
  font-size: 0.9em;
}

button {
  display: block;
  margin-top: 10px;
  width: 100px; /* Makes it wider */
  // height: 30px; /* Makes it shorter */
  font-size: 10px; /* Adjust text size for better readability */
  text-align: center;
  background-color: #f58220; /* Match the existing orange color */
  color: white;
  border: none;
  border-radius: 8px; /* Rounded corners for a sleek look */
  cursor: pointer;
}

button:hover {
  background-color: #e6731f; /* Darker shade on hover */
}

// .container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
//   min-height: 100vh; /* Ensures full height usage */
// }

// Mobile Small (<= 480px)
@media screen and (max-width: 480px) {
  .search-main {
    padding: 40px 0 180px;
  }

  .container {
    padding: 0 16px;
  }

  .search-title {
    font-size: 18px;
  }
}

// Mobile Large (481px - 733px)
@media screen and (max-width: 733px) {
  .search-main {
    padding: 56px 0 224px;
    background-position: bottom center;
    background-size: contain;
  }

  .content-wrapper {
    min-width: 328px;
  }
}

// Tablet (734px - 960px)
@media screen and (min-width: 734px) and (max-width: 960px) {
  .search-main {
    padding: 136px 0 433px;
    background-position: bottom center;
    background-size: contain;
  }
}

// Desktop (961px - 1500px)
@media screen and (min-width: 961px) and (max-width: 1500px) {
  .search-main {
    padding: 120px 0 120px;
    background-position: 22rem 2.5rem;
  }
}

// Large Desktop (> 1500px)
@media screen and (min-width: 1501px) {
  .search-main {
    padding: 120px 0 120px;
    background-position: 72% center;
  }
}
</style>
